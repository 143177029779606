/* Distribuidor.css */

.distribuidores-container {
    padding: 20px;
    max-width: 1200px;
    margin: 0 auto;
    font-family: Arial, sans-serif;
  }
  
  .title {
    font-size: 2.5em;
    margin-bottom: 20px;
    color: #2c3e50;
    text-align: center;
  }
  
  .estado-section {
    margin-bottom: 30px;
  }
  
  .estado-title {
    font-size: 2em;
    color: #27ae60;
    border-bottom: 2px solid #27ae60;
    padding-bottom: 5px;
    margin-bottom: 15px;
  }
  
  .distribuidor-item {
    padding: 10px 0;
  }
  
  .distribuidor-item h3 {
    font-size: 1.5em;
    color: #34495e;
  }
  
  .distribuidor-item p {
    font-size: 1.2em;
    color: #7f8c8d;
    margin: 5px 0;
  }
  