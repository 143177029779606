body {
  margin: 0;
  font-family: "Ubuntu Condensed";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.navbar {
  background-color: white;
  height: 100px;
  padding: 5px 10%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  z-index: 2;
}

.logo {
  height: 90px;
}

.menu {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
}

.menu li {
  margin-right: 20px;
}

.nav-link {
  color: #232b5e;
  text-decoration: none;
  font-weight: bold;
}

.nav-link:hover {
  color: #179847;
}

/* Styles for hamburger menu and overlay */
.hamburger {
  display: none;
  flex-direction: column;
  justify-content: space-between;
  width: 30px;
  height: 20px;
  cursor: pointer;
}

.hamburger .bar {
  width: 100%;
  height: 3px;
  background-color: #01691b;
  transition: transform 0.3s, opacity 0.3s;
}

/* Menu and overlay styles for mobile */
@media (max-width: 768px) {
  .hamburger {
    display: flex;
  }

  .menu {
    display: none;
    flex-direction: column;
    position: absolute;
    top: 100px;
    left: 0;
    width: 100%;
    background-color: white;
    padding: 20px 0;
  }

  .menu-open {
    display: flex;
  }

  .menu li {
    margin: 10px 0;
    text-align: center;
  }
}

/* Overlay styles */
.overlay {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
}

.overlay-active {
  display: block;
}

/* Bar animation for hamburger */
.bar-open:nth-child(1) {
  transform: rotate(45deg) translate(5px, 5px);
  
}

.bar-open:nth-child(2) {
  opacity: 0;
}

.bar-open:nth-child(3) {
  transform: rotate(-45deg) translate(5px, -5px);
  
}



/*MAIN SECTION 1 */
.main-1{
background: url(../public/media/main-1-bg.png);
height: 70vh;

}

.main-1-text h1 {
  font-size: 48px;
  color: #fff;
  text-align: left;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  margin-top: 4px;
}

.btn-distribuidor {
  display: inline-block;
  padding: 10px 20px;
  font-family: "Ubuntu Condensed";
  font-size: 16px;
  text-decoration: none;
  text-align: center;
  color: #fff;
  background-color: #179847;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.btn-distribuidor:hover {
  background-color: #12c22f;
}




/*MAIN SECTION 2*/

.section-title {
  text-align: center;
  font-size: 28px;
  color: #179847;
}

.background2 {
  background: url(../public/media/main-2-bg.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: auto;
}

.main-2 {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 5% 8%;
  height: auto;
  gap: 1.6rem;
}

.main-2-txt {
  width: 50%;
}

.main-2-txt h2 {
  font-size: 32px;
  color: #002B45;
}

.main-2-txt p {
  font-size: 24px;
}

.main-2-img {
  width: 50%;
}

.main-2-img img {
  width: 100%;
}

.btn-container {
  display: flex;
  gap: 4px;
}

.btn-distribuidor {
  display: inline-block;
  padding: 10px 20px;
  font-family: "Ubuntu Condensed";
  font-size: 16px;
  text-decoration: none;
  text-align: center;
  color: #fff;
  background-color: #179847;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.btn-distribuidor:hover {
  background-color: #12c22f;
}

/* Media queries for tablets */
@media (max-width: 768px) {
  .main-2 {
    flex-direction: column;
    padding: 5% 5%;
    gap: 1rem;
  }

  .main-2-txt {
    width: 100%;
  }

  .main-2-img {
    width: 100%;
  }

  .main-2-img img {
    width: 100%;
    height: auto;
  }

  .btn-container {
    flex-direction: column;
    align-items: center;
  }

  .btn-container .btn-distribuidor {
    width: 100%;
    margin-top: 8px;
  }

  .main-2-txt h2 {
    font-size: 28px;
  }

  .main-2-txt p {
    font-size: 20px;
  }
}

/* Media queries for mobile devices */
@media (max-width: 480px) {
  .main-2 {
    padding: 5% 5%;
  }

  .section-title h2 {
    font-size: 24px;
  }

  .main-2-txt h2 {
    font-size: 24px;
  }

  .main-2-txt p {
    font-size: 18px;
  }

  .btn-container {
    flex-direction: column;
  }

  .btn-container .btn-distribuidor {
    width: 60%;
    margin-top: 8px;
    font-size: 14px;
    padding: 8px 16px;
  }
}


/*MAIN SECTION 3*/

.main-section {
  height: auto;
  padding: 0 5%;
}

.section-title {
  text-align: center;
  padding: 10px;
  font-size: 28px;
  color: #179847;
}

.section-title2 {
  text-align: center;
  padding: 10px;
  font-size: 28px;
  color: #002B45;
}

.cards-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
  justify-content: center;
  padding: 20px;
}

.card-wrapper {
  display: flex;
  justify-content: center;
}

/* Media queries for responsiveness */

/* For large screens */
@media (min-width: 1200px) {
  .section-title, .section-title2 {
    font-size: 32px;
  }

  .cards-container {
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 30px;
  }
}

/* For tablets */
@media (max-width: 768px) {
  .section-title, .section-title2 {
    font-size: 24px;
  }

  .cards-container {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 20px;
  }
}

/* For mobile devices */
@media (max-width: 480px) {
  .section-title, .section-title2 {
    font-size: 20px;

  }

  .cards-container {
    grid-template-columns: 1fr;
    gap: 15px;
    padding: 5%;
  }
}


.review-card {
  background-color: #fff;
  width: 100%;
  max-width: 300px;
  margin: 20px auto;
  padding: 20px;
  border: 1px solid #000;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  transition: all ease 300ms;
}

.review-card:hover {
  transform: scale(1.1);
}

.profile-pic img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin-bottom: 10px;
}

.review-info h3 {
  font-size: 18px;
  margin-bottom: 5px;
}

.star-rating {
  font-size: 20px;
  color: gold;
  margin-bottom: 5px;
}

.comment {
  font-size: 16px;
  margin-bottom: 10px;
}

.date {
  font-size: 14px;
  color: #888;
}

/* Media queries for responsive design */

/* For large screens */
@media (min-width: 1200px) {
  .review-card {
    max-width: 400px;
  }

  .review-info h3 {
    font-size: 20px;
  }

  .star-rating {
    font-size: 22px;
  }

  .comment {
    font-size: 18px;
  }

  .date {
    font-size: 16px;
  }
}

/* For tablets */
@media (max-width: 768px) {
  .review-card {
    max-width: 80%;
    padding: 15px;
  }

  .profile-pic img {
    width: 80px;
    height: 80px;
  }

  .review-info h3 {
    font-size: 16px;
  }

  .star-rating {
    font-size: 18px;
  }

  .comment {
    font-size: 14px;
  }

  .date {
    font-size: 12px;
  }
}

/* For mobile devices */
@media (max-width: 480px) {
  .review-card {
    max-width: 90%;
    padding: 10px;
  }

  .profile-pic img {
    width: 60px;
    height: 60px;
  }

  .review-info h3 {
    font-size: 14px;
  }

  .star-rating {
    font-size: 16px;
  }

  .comment {
    font-size: 12px;
  }

  .date {
    font-size: 10px;
  }
}


/* PRODUCT CARROUSEL MAIN PAGE*/
.product-item {
  height: 300px;
  padding: 5% 0;
}

.product-item img {
  width: 100%;
  height: 100%;
  object-fit: scale-down;
  /* Ajusta el tamaño de la imagen dentro del contenedor */
}

/*NOSOTROS*/
.about-section-1 {
  background: url(../public/media/About-bg.png);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: auto;
  width: 100%;

}

.about-section-1-over {
  width: 50%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.7);
  display: flex;
  flex-direction: column;
  text-align: left;
  font-size: 20px;
  padding: 4%;
  color: #002B45;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  clip-path: polygon(0 0, 100% 0, calc(100% - 100px) 100%, 0 100%);
}
/* For laptops */
@media (max-width: 1440px) {
  .about-section-1-over {
    width: 80%;
    font-size: 18px;
    padding-right: 10%;
    
  }
}

/* For tablets */
@media (max-width: 1024px) {
  .about-section-1{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: auto;
  }
  .about-section-1-over {
    width: 80%;
    font-size: 20px;
    clip-path: none;
    height: auto;
    
  }
}

/* For mobile devices */
@media (max-width: 768px) {
  .about-section-1-over {
    width: 80%;
    font-size: 18px;
    padding: 8%;
  }
}

.about-section {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 10%;
  text-align: left;
}

.about-section div {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

}

.about-section h3 {
  font-size: 24px;
  color: #333;
  margin-bottom: 10px;
}

.about-section p {
  font-size: 16px;
  color: #666;
  line-height: 1.6;
}

.about-section img {
  width: 100%;
  max-width: 400px;
  height: auto;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
/* For laptops */
@media (max-width: 1440px) {
  .about-section {
    padding: 20px 5%;
    gap: 1.5rem;
  }

  .about-section div {
    width: 100%;
    margin-bottom: 20px;
  }

  .about-section img {
    max-width: 80%;
  }
}

/* For tablets */
@media (max-width: 1024px) {
  .about-section p {
    flex-direction: column;
    font-size: 14px;
  }
}

/* For mobile devices */
@media (max-width: 768px) {
  .about-section {
    flex-direction: column;
    padding: 20px 8%;
  }

  .about-section div {
    width: 100%;
  }

  .about-section h3 {
    font-size: 20px;
  }

  .about-section p {
    font-size: 18px;
  }

  .about-section img {
    max-width: 100%;
  }
}


.about-2{
  height: auto;
  padding: 5%;
}


.valores-icons-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30%;
  text-align: center;
}

.valores-icons-container div {
  position: relative;
  cursor: pointer;
}

.valores-icons-container div h3 {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  transition: opacity 0.3s ease;
  
}

.valores-icons-container div:hover h3 {
  opacity: 1;
}

.valores-icons-container img {
  width: 100px; /* Adjust size as needed */
  height: auto;
  transition: all ease 300ms;
}

.valores-icons-container img:hover {
  transform: scale(1.1);
}

/* For laptops */
@media (max-width: 1440px) {
  .valores-icons-container {
    width: 50%;
  }
}

/* For tablets */
@media (max-width: 1024px) {
  .valores-icons-container {
    width: 70%;
  }

  .valores-icons-container div h3 {
    font-size: 14px;
  }
}

/* For mobile devices */
@media (max-width: 768px) {
  .valores-icons-container {
    width: 90%;
    flex-wrap: wrap;
  }

  .valores-icons-container div {
    width: 50%;
  }

  .valores-icons-container div h3 {
    font-size: 12px;
  }
}


/* Footer Styles */
footer {
  background-color: #002B45;
  background-size: auto;
  color: #fff;
  text-align: center;
  padding: 1.6rem 8%;
}

nav ul {
  list-style-type: none;
  padding: 0;
  color: #002B45;
}

nav ul li {
  display: inline;
  margin-right: 20px;
}

.contact-info {
  margin-top: 20px;
}

.contact-info p {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
}

.contact-info p span {
  color: #000;
  font-weight: 500;
  padding-left: 4px;
}

.social-media {
  margin-top: 20px;
}

.logo1 {
  width: 50px;
  height: 50px;
  margin-right: 10px;
}

nav ul li a {
  color: #fff;
  text-align: left;
  text-decoration: none;
  transition: color 0.3s ease;
}

nav ul li a:hover {
  color: #39654a;
}

.social-media a {
  color: #ffff;
  margin: 0 10px;
  text-decoration: none;
  transition: color 0.3s ease;
}

.fa-facebook {
  color: #316ff6;
}

.fa-instagram {
  color: #d62976;
}

/* Media queries for tablets */
@media (max-width: 768px) {
  nav ul li {
    display: block;
    margin: 10px 0;
  }

  .contact-info p {
    flex-direction: column;
  }

 

  .social-media a {
    margin: 10px 0;
  }
}

/* Media queries for mobile devices */
@media (max-width: 480px) {
  footer {
    padding: 1rem 5%;
  }

  nav ul li {
    margin: 8px 0;
  }

  .contact-info {
    margin-top: 15px;
  }

  .social-media {
    margin-top: 15px;
  }

  .contact-info p,
  .copyright p {
    flex-direction: column;
    text-align: center;
  }

  .contact-info p span {
    display: block;
    padding-left: 0;
  }

  .social-media a {
    margin: 8px 8px;
  }
}

/* PRODUCTS SCREEN */

.pscreen-container {
  margin: 20px;
}

.pscreen-banner {
  background-color: #f11616;
  width: 100%;
  height: auto;
  padding: 4px 0;
  text-align: center;
  color: white;
  border-radius: 25px;
  text-transform: uppercase; /* Convert text to uppercase */
}

.pscreen-banner h1,
.pscreen-banner h2 {
  margin:  0;
  
}

#countdown {
  font-size: 24px;
  font-weight: bold;
  color: #fff;  text-transform: lowercase; /* Convert text to uppercase */
}


.pscreen-grid {
  padding: 4%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px;
}

.pscreen-card {
  width: 250px; /* Define a fixed width */
  height: 250px; /* Define a fixed height to make it square */
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center; /* Center items in the card */
  transition: transform 0.3s ease; /* Smooth transition for scaling */
}

.pscreen-card:hover {
  transform: scale(1.1); /* Scale up on hover */
}

.pscreen-img-container {
  width: 100%;
  height: 150px; /* Adjusted height for image container within the square card */
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden; /* Hide overflow to maintain uniformity */
}

.pscreen-img {
  width: 100%; /* Ocupa todo el ancho del contenedor */
  height: 280px; /* Altura fija para que sea cuadrado */
  object-fit: scale-down; /* Ensure images cover the container proportionally */
  object-position: center; /* Centra el producto dentro del cuadro */
  border-radius: 5px;
}

.pscreen-details {
  margin-top: 10px;
  text-align: left;
  text-decoration: none;
  color: #232b5e;
  line-height: 1.2;
  word-wrap: break-word; /* Ensure long words wrap to the next line */
  overflow-wrap: break-word; /* Ensure long words wrap to the next line */
  max-width: 200px; /* Adjust this value based on your card's width */
}

.pscreen-details .details {
  text-transform: uppercase; /* Convert text to uppercase */
}
.pscreen-sizes {
  display: flex;
  flex-wrap: wrap; /* Ensures sizes wrap to the next line if too long */
}

.pscreen-sizes div {
  margin-right: 5px; /* Space between sizes */
}

.pscreen-stars{
  color: #ffd001;
}

.load-more {
  display: inline-block;
  padding: 12px 24px;
  margin: 20px auto;
  font-size: 16px;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
}

.load-more:hover {
  background-color: #0056b3;
  transform: scale(1.05);
}

.load-more:active {
  background-color: #003d80;
}

.pscreen-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.pscreen-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 16px;
}

/* General Layout */
.product-screen {
    display: flex;
    justify-content: space-evenly;
    align-items: top;
    padding: 2% ;
    color: #333;
    font-family: 'Arial', sans-serif;
  
}

.product-info {
    text-align: center;
    margin-bottom: 40px;
    background-color: transparent;
    padding: 20px;
    border-radius: 10px;

}

.product-image {
    width: auto;
    height: 350px;
    border-radius: 10px;
    margin-bottom: 20px;

}

h2 {
    color: #2c3e50;
    margin: 10px 0;
}

h3 {
    color: #16a085;
    margin-bottom: 20px;
}

.product-section {
    width: auto;
    margin-bottom: 40px;
    padding: 20px;
    background-color: transparent;
    border-radius: 10px;

}

h4 {
    color: #2980b9;
    margin-bottom: 20px;
    border-bottom: 2px solid #16a085;
    padding-bottom: 10px;
}

.product-composition, .product-presentations {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.product-composition-item, .product-presentation-item {
    display: flex;
    justify-content: space-between;
    padding: 15px 20px;
    background-color: #ecf0f1;
    border-radius: 5px;
    color: #2c3e50;
    transition: background-color 0.3s ease;
}

.product-composition-item:hover, .product-presentation-item:hover {
    background-color: #d0dce0;
}

.component-name, .presentation-size {
    font-weight: bold;
    color: #2980b9;
}

.component-amount, .presentation-packaging, .presentation-price {
    color: #16a085;
    font-weight: bold;
}

/* Product Grid */
.product-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  padding:  5%;
  gap: 20px;
}

.product-grid-item {
  background-color: #fff;
  border-radius: 10px;
  padding: 20px;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  text-decoration: none;
}

.product-grid-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
}

.grid-product-image {
  width: auto;
  height: 200px;
  border-radius: 10px;
  margin-bottom: 10px;
}

.randomh5 {
  color: #2c3e50;
  margin: 10px 0;
}

.randomp {
  color: #16a085;
  margin-bottom: 10px;
}

/* Media Queries */
@media (max-width: 1024px) {
  .product-screen {
      padding: 2% 4%;
      flex-direction: column;
  }

  .product-image {
      height: 300px;
  }

  .product-section {
      width: auto;
  }

  .product-composition-item, .product-presentation-item {
      padding: 10px 15px;
  }

  .product-grid {
      grid-template-columns: repeat(2, 1fr);
  }

  .grid-product-image {
      height: 150px;
  }
}

@media (max-width: 768px) {
  .product-screen {
      padding: 2% 5%;
  }

  .product-info {
      margin-bottom: 30px;
  }

  .product-image {
      height: 250px;
  }



  .product-composition-item, .product-presentation-item {
      padding: 10px;
  }

  .product-grid {
      grid-template-columns: 1fr;
  }

  .grid-product-image {
      height: 150px;
  }

  h2, h3 {
      font-size: 1.2em;
  }

  h4 {
      font-size: 1em;
  }
}

.download-button {
  position: fixed; /* Mantener el botón fijo en la ventana */
  bottom: 20px; /* Espaciado desde la parte inferior */
  right: 20px; /* Espaciado desde la derecha */
  padding: 10px 15px;
  background-color: #4CAF50; /* Fondo verde */
  color: white; /* Texto blanco */
  text-decoration: none;
  border-radius: 5px;
  font-weight: bold;
  z-index: 100; /* Asegurarse de que esté por encima de otros elementos */
  transition: background-color 0.3s; /* Para efectos al pasar el mouse */
}

.download-button:hover {
  background-color: #45a049; /* Cambiar el color al pasar el mouse */
}

.product-screen {
  margin-top: 60px; /* Asegurar que el contenido no se superponga con el botón */
}